/* Plus Jakarta Sans */
@font-face {
    font-family: jakarta;
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/plus-jakarta/PlusJakartaSans-ExtraLight.ttf');
    font-display: swap;
}

@font-face {
    font-family: jakarta;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/plus-jakarta/PlusJakartaSans-Light.ttf');
    font-display: swap;
}

@font-face {
    font-family: jakarta;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/plus-jakarta/PlusJakartaSans-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: jakarta;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/plus-jakarta/PlusJakartaSans-Medium.ttf');
    font-display: swap;
}

@font-face {
    font-family: jakarta;
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/plus-jakarta/PlusJakartaSans-SemiBold.ttf');
    font-display: swap;
}

@font-face {
    font-family: jakarta;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/plus-jakarta/PlusJakartaSans-Bold.ttf');
    font-display: swap;
}

/* Termina Test */
@font-face {
    font-family: termina;
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/termina/TerminaTest-ExtraLight.otf');
    font-display: swap;
}

@font-face {
    font-family: termina;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/termina/TerminaTest-Light.otf');
    font-display: swap;
}

@font-face {
    font-family: termina;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/termina/TerminaTest-Regular.otf');
    font-display: swap;
}

@font-face {
    font-family: termina;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/termina/TerminaTest-Medium.otf');
    font-display: swap;
}

@font-face {
    font-family: termina;
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/termina/TerminaTest-Demi.otf');
    font-display: swap;
}

@font-face {
    font-family: termina;
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/termina/TerminaTest-Bold.otf');
    font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #0E1318;
  --foreground: #FFFFFF;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: jakarta, serif;
  scroll-behavior: smooth;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(25, 134, 188, 1);
  transition: background-color 5000s ease-in-out 0s;
}

.font-termina {
  font-family: termina, serif;
}

.font-jakarta {
  font-family: jakarta, serif;
}

.hide-scroll-bar::-webkit-scrollbar {
  @apply hidden
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.Toastify__toast-theme--dark {
  background-color: rgb(20, 26, 31) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4) !important;
}

/* DatePickerCustom.css */

/* Customize the calendar container */
.custom-calendar {
  @apply border border-tg-border bg-tg-bg-header text-tg-text-title;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.custom-calendar .react-datepicker__header {
  @apply bg-tg-bg-header border-b border-tg-border;
}

.react-datepicker__month {
  @apply bg-tg-bg-header border-tg-border text-center;
}

.custom-calendar .react-datepicker__current-month,
.custom-calendar .react-datepicker__day-name {
  @apply text-tg-text-title;
  font-weight: bold;
  /*width: 40px;*/
  text-align: center;
  margin: 5px 8.5px;
}

/* Navigation buttons */
.custom-calendar .react-datepicker__navigation--previous,
.custom-calendar .react-datepicker__navigation--next {
  top: 16px;
  line-height: 1.5;
}

.custom-calendar .react-datepicker__navigation-icon::before {
  @apply border-tg-text-title;
}

/* Day cells */
.custom-calendar .react-datepicker__day {
  @apply text-tg-text-title transition-all duration-300;
  text-decoration: none !important;
  font-weight: normal;
  width: 40px;
  line-height: 40px;
  margin: 2px;
}

.custom-calendar .react-datepicker__day--disabled {
  @apply text-tg-text-normal;
  text-decoration: none !important;
  font-weight: normal;
  width: 40px;
  line-height: 40px;
  margin: 2px;
  cursor: not-allowed;
}

.custom-calendar .react-datepicker__day:hover {
  @apply bg-tg-bg-btn text-tg-text-important !important;
  border-radius: 50%;
}

.custom-calendar .react-datepicker__day--disabled:hover {
  @apply bg-tg-background !important;
  border-radius: 0.5rem;
}

.custom-calendar .react-datepicker__day--selected,
.custom-calendar .react-datepicker__day--keyboard-selected {
  @apply bg-tg-bg-btn text-tg-text-title;
  border-radius: 50%;
}

.custom-calendar .react-datepicker__day--today {
  @apply text-tg-bg-btn;
  font-weight: bold;
  background: none !important;
  text-decoration: underline;
}

/* Adjust the placement */
.custom-calendar.react-datepicker {
  font-size: 14px;
}

/* Adjust the padding and margins */
.custom-calendar .react-datepicker__month {
  margin: 0;
}

/* Time picker styles (if using time selection) */
.custom-calendar .react-datepicker__time-container {
  @apply border-l border-tg-border;
}

.custom-calendar .react-datepicker__time {
  @apply bg-tg-bg-header;
}

.custom-calendar .react-datepicker__time-box {
  width: 100%;
}

.custom-calendar .react-datepicker__time-list-item--selected {
  @apply bg-tg-bg-btn text-tg-text-title;
}
/* DatePickerCustom.css */

.custom-calendar {
  @apply border border-tg-border rounded-lg bg-tg-bg-header text-tg-text-title overflow-hidden shadow-lg;
}

/* CustomTimePicker.css */

.custom-timepicker {
  @apply bg-tg-bg-header border border-tg-border rounded-lg shadow-lg;
}

.custom-timepicker div {
  @apply text-tg-text-title px-4 py-2 cursor-pointer transition-all duration-300;
}

.custom-timepicker div:hover {
  @apply bg-tg-bg-btn text-tg-text-important;
}

.custom-timepicker .selected {
  @apply bg-tg-bg-btn text-tg-text-title font-bold;
}



/* Stripe Customization */
.App-Background {
  /*@apply bg-tg-bg-box !important;*/
  background: rgb(20, 26, 31) !important;
}

.App-Payment {
  background-color: rgb(20, 26, 31) !important;
}
